<template>
  <v-card-text class="mt-4 pb-0">
    <p>We have divided the entire world into hexagons (which we call <strong>tiles</strong>) of ~50 km (31 mi) in radius. Each activity you complete is automatically assigned to the correct tile(s). And for each tile we create local leaderboards. Will you be this month's tile king or queen?</p>
    <p>Tiles and rankings are in <code>beta</code> phase. Do you have feedback or suggestions? Please <router-link to="/contact">let us know</router-link>!</p>

    <QuickSurvey v-if="false" name="202103-tiles" question="What do you think of the tiles and tile rankings?" :items="[{value: 'very', text: '😀 I\'m addicted'},  {value: 'like', text: '😊 I like it'}, {value: 'neutral', text: '😐  Not sure'}]" />
  </v-card-text>
</template>

<script>
import QuickSurvey from "@/components/QuickSurvey";

export default {
  name: "TileMapIntro",
  components: {
    QuickSurvey,
  },
  props: {
  },
  data: function() {
    return {
    };
  },
};
</script>

<style scoped>
</style>